<template>
  <content-container
    :title="title"
    :subtitle="subtitle"
    :description="description">
    <div class="image">
      <img
        :src="image"
        v-if="image"
      />
    </div>
  </content-container>
</template>

<script>
  import ContentMixin from '../../mixin/content'

  export default {
    props: [
      'wide',
      'category',
      'breadcrumb',
      'splitviewMode',
    ],
    computed: {
      image() {
        if (this.category) {
          const media = this.category?.media?.links?.find(l => l.rel === 'download')
          if (media) {
            return media.href
          }
        }
        return this.category?.media?.links[0]?.href
      },
    },

    mixins: [
      ContentMixin
    ]
  }
</script>

<style lang="scss" scoped>
  .image {
    @apply
      flex
      justify-center;

    img {
      @apply
        max-w-full
        max-h-full;
    }
  }
</style>